
import Redacao from "@/interfaces/Redacao";
import router from "@/router";
import BaseService from "@/services/admin/BaseService";
import store from "@/store";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const $q = useQuasar();
    const route = useRoute();
    const redacoes = ref<Redacao[]>();
    const goTo = (rota: string): void => {
      router.push({ name: rota });
    };

    const getRedacoes = (): void => {
      BaseService.list(`redacoes/find-by-disciplina/${route.params.id}`)
        .then((res) => {
          redacoes.value = res.data;
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao buscar redações",
          });
        });
    };

    onMounted(() => {
      getRedacoes();
    });

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    return { redacoes, goTo, store };
  },
});
